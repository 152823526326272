define("ember-phone-input/services/phone-input", ["exports", "@ember/service", "rsvp"], function (_exports, _service, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _service.default.extend({
    intlTelInput: null,
    load() {
      if (this.intlTelInput) return;
      return _rsvp.default.all([emberAutoImportDynamic("intl-tel-input/build/js/intlTelInput.js"), emberAutoImportDynamic("intl-tel-input/build/js/utils.js")]).then(([intlTelInput]) => {
        if (!this.isDestroying && !this.isDestroyed) {
          this.set('intlTelInput', intlTelInput.default);
        }
      });
    }
  });
});