define("ember-page-title/services/page-title-list", ["exports", "@ember/application", "@ember/runloop", "@ember/service", "@ember/utils", "@ember/debug"], function (_exports, _application, _runloop, _service, _utils, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let isFastBoot = typeof FastBoot !== 'undefined';
  const RouterEvent = {
    ROUTE_DID_CHANGE: 'routeDidChange'
  };

  /**
    @class page-title-list
    @extends Ember.Service
   */
  let PageTitleListService = _exports.default = (_dec = (0, _service.inject)('page-title'), _dec2 = (0, _service.inject)('router'), _dec3 = (0, _service.inject)('-document'), _class = class PageTitleListService extends _service.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "pageTitle", _descriptor, this);
      _initializerDefineProperty(this, "router", _descriptor2, this);
      // in fastboot context "document" is instance of
      // ember-fastboot/simple-dom document
      _initializerDefineProperty(this, "document", _descriptor3, this);
      _defineProperty(this, "tokens", []);
      _defineProperty(this, "_defaultConfig", {
        // The default separator to use between tokens.
        separator: ' | ',
        // The default prepend value to use.
        prepend: true,
        // The default replace value to use.
        replace: null
      });
      _defineProperty(this, "scheduleTitleUpdate", () => {
        (0, _runloop.scheduleOnce)('afterRender', this, this._updateTitle);
      });
      this._validateExistingTitleElement();
      let config = (0, _application.getOwner)(this).resolveRegistration('config:environment');
      if (config.pageTitle) {
        ['separator', 'prepend', 'replace'].forEach(key => {
          if (!(0, _utils.isEmpty)(config.pageTitle[key])) {
            this._defaultConfig[key] = config.pageTitle[key];
          }
        });
      }
      this.router.on(RouterEvent.ROUTE_DID_CHANGE, this.scheduleTitleUpdate);
    }
    applyTokenDefaults(token) {
      let defaultSeparator = this._defaultConfig.separator;
      let defaultPrepend = this._defaultConfig.prepend;
      let defaultReplace = this._defaultConfig.replace;
      if (token.separator == null) {
        token.separator = defaultSeparator;
      }
      if (token.prepend == null && defaultPrepend != null) {
        token.prepend = defaultPrepend;
      }
      if (token.replace == null && defaultReplace != null) {
        token.replace = defaultReplace;
      }
    }
    inheritFromPrevious(token) {
      let previous = token.previous;
      if (previous) {
        if (token.separator == null) {
          token.separator = previous.separator;
        }
        if (token.prepend == null) {
          token.prepend = previous.prepend;
        }
      }
    }
    push(token) {
      let tokenForId = this._findTokenById(token.id);
      if (tokenForId) {
        let index = this.tokens.indexOf(tokenForId);
        let tokens = [...this.tokens];
        let previous = tokenForId.previous;
        token.previous = previous;
        token.next = tokenForId.next;
        this.inheritFromPrevious(token);
        this.applyTokenDefaults(token);
        tokens.splice(index, 1, token);
        this.tokens = tokens;
        return;
      }
      let previous = this.tokens.slice(-1)[0];
      if (previous) {
        token.previous = previous;
        previous.next = token;
        this.inheritFromPrevious(token);
      }
      this.applyTokenDefaults(token);
      this.tokens = [...this.tokens, token];
    }
    remove(id) {
      let token = this._findTokenById(id);
      let {
        next,
        previous
      } = token;
      if (next) {
        next.previous = previous;
      }
      if (previous) {
        previous.next = next;
      }
      token.previous = token.next = null;
      let tokens = [...this.tokens];
      tokens.splice(tokens.indexOf(token), 1);
      this.tokens = tokens;
    }
    get visibleTokens() {
      let tokens = this.tokens;
      let i = tokens ? tokens.length : 0;
      let visible = [];
      while (i--) {
        let token = tokens[i];
        if (token.replace) {
          visible.unshift(token);
          break;
        } else {
          visible.unshift(token);
        }
      }
      return visible;
    }
    get sortedTokens() {
      let visible = this.visibleTokens;
      let appending = true;
      let group = [];
      let groups = [group];
      let frontGroups = [];
      visible.forEach(token => {
        if (token.front) {
          frontGroups.unshift(token);
        } else if (token.prepend) {
          if (appending) {
            appending = false;
            group = [];
            groups.push(group);
          }
          let lastToken = group[0];
          if (lastToken) {
            token = {
              ...token
            };
            token.separator = lastToken.separator;
          }
          group.unshift(token);
        } else {
          if (!appending) {
            appending = true;
            group = [];
            groups.push(group);
          }
          group.push(token);
        }
      });
      return frontGroups.concat(groups.reduce((E, group) => E.concat(group), []));
    }
    toString() {
      let tokens = this.sortedTokens;
      let title = [];
      for (let i = 0, len = tokens.length; i < len; i++) {
        let token = tokens[i];
        if (token.title) {
          title.push(token.title);
          if (i + 1 < len) {
            title.push(token.separator);
          }
        }
      }
      return title.join('');
    }
    willDestroy() {
      super.willDestroy();
      this.router.off(RouterEvent.ROUTE_DID_CHANGE, this.scheduleTitleUpdate);
    }
    _updateTitle() {
      const toBeTitle = this.toString();
      if (isFastBoot) {
        this.updateFastbootTitle(toBeTitle);
      } else {
        /**
         * When rendering app with "?fastboot=false" (http://ember-fastboot.com/docs/user-guide#disabling-fastboot)
         * We will not have <title> element present in DOM.
         *
         * But this is fine as by HTML spec,
         * one is created upon assigning "document.title" value;
         *
         * https://html.spec.whatwg.org/multipage/dom.html#dom-tree-accessors
         */
        this.document.title = toBeTitle;
      }
      this.pageTitle.titleDidUpdate(toBeTitle);
    }

    /**
     * Validate if there's more than one title element present.
     *
     * Example: ember-cli-head can cause conflicting updates.
     * @private
     */
    _validateExistingTitleElement() {
      if (isFastBoot) {
        return;
      }
      (false && !(document.head.querySelectorAll('title').length <= 1) && (0, _debug.assert)('[ember-page-title]: Multiple title elements found. Check for other addons like ember-cli-head updating <title> as well.', document.head.querySelectorAll('title').length <= 1));
    }

    /**
     * Find token by id
     *
     * IE11 compatible approach due to lack of Array.find support
     *
     * @param {String} id
     * @private
     */
    _findTokenById(id) {
      return this.tokens.filter(token => {
        return token.id === id;
      })[0];
    }
    updateFastbootTitle(toBeTitle) {
      if (!isFastBoot) {
        return;
      }
      const headElement = this.document.head;
      const headChildNodes = headElement.childNodes;

      // Remove existing title elements from previous render cycle
      for (let i = 0; i < headChildNodes.length; i++) {
        let node = headChildNodes[i];
        if (node.nodeName.toLowerCase() === 'title') {
          headElement.removeChild(node);
        }
      }

      // Add title element with latest value
      let titleEl = this.document.createElement('title');
      let titleContents = this.document.createTextNode(toBeTitle);
      titleEl.appendChild(titleContents);
      headElement.appendChild(titleEl);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "pageTitle", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "document", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
});