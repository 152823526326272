define("ember-cp-validations/-private/result", ["exports", "@ember/utils", "@ember/array", "@ember/object", "@ember/object/computed", "ember-cp-validations/validations/result-collection", "ember-cp-validations/validations/warning-result-collection", "ember-cp-validations/-private/internal-result-object"], function (_exports, _utils, _array, _object, _computed, _resultCollection, _warningResultCollection, _internalResultObject) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * __PRIVATE__
   *
   * @module Validations
   * @class Result
   * @private
   */

  const Result = _object.default.extend({
    /**
     * @property model
     * @type {Object}
     */
    model: null,
    /**
     * @property attribute
     * @type {String}
     */
    attribute: '',
    /**
     * @property _promise
     * @async
     * @private
     * @type {Promise}
     */
    _promise: null,
    /**
     * The validator that returned this result
     * @property _validator
     * @private
     * @type {Validator}
     */
    _validator: null,
    /**
     * Determines if the _result object is readOnly.
     *
     * This is needed because ResultCollections and global validation objects control their own
     * state via CPs
     *
     * @property _isReadOnly
     * @private
     * @readOnly
     * @type {Boolean}
     */
    _isReadOnly: (0, _object.computed)('_result', function () {
      let validations = (0, _object.get)(this, '_result');
      return validations instanceof _resultCollection.default || (0, _object.get)(validations, 'isValidations');
    }).readOnly(),
    /**
     * @property isWarning
     * @readOnly
     * @type {Boolean}
     */
    isWarning: (0, _computed.readOnly)('_validator.isWarning'),
    /**
     * @property isValid
     * @readOnly
     * @type {Boolean}
     */
    isValid: (0, _computed.readOnly)('_result.isValid'),
    /**
     * @property isInvalid
     * @readOnly
     * @type {Boolean}
     */
    isInvalid: (0, _computed.readOnly)('_result.isInvalid'),
    /**
     * @property isValidating
     * @readOnly
     * @type {Boolean}
     */
    isValidating: (0, _computed.readOnly)('_result.isValidating'),
    /**
     * @property isTruelyValid
     * @readOnly
     * @type {Boolean}
     */
    isTruelyValid: (0, _computed.readOnly)('_result.isTruelyValid'),
    /**
     * @property isTruelyInvalid
     * @readOnly
     * @type {Boolean}
     */
    isTruelyInvalid: (0, _computed.readOnly)('_result.isTruelyInvalid'),
    /**
     * @property isAsync
     * @readOnly
     * @type {Boolean}
     */
    isAsync: (0, _computed.readOnly)('_result.isAsync'),
    /**
     * @property message
     * @readOnly
     * @type {String}
     */
    message: (0, _computed.readOnly)('_result.message'),
    /**
     * @property messages
     * @readOnly
     * @type {Array}
     */
    messages: (0, _computed.readOnly)('_result.messages'),
    /**
     * @property error
     * @readOnly
     * @type {Object}
     */
    error: (0, _computed.readOnly)('_result.error'),
    /**
     * @property errors
     * @readOnly
     * @type {Array}
     */
    errors: (0, _computed.readOnly)('_result.errors'),
    /**
     * @property warningMessage
     * @readOnly
     * @type {String}
     */
    warningMessage: (0, _computed.readOnly)('_result.warningMessage'),
    /**
     * @property warningMessages
     * @readOnly
     * @type {Array}
     */
    warningMessages: (0, _computed.readOnly)('_result.warningMessages'),
    /**
     * @property warning
     * @readOnly
     * @type {Object}
     */
    warning: (0, _computed.readOnly)('_result.warning'),
    /**
     * @property warnings
     * @readOnly
     * @type {Array}
     */
    warnings: (0, _computed.readOnly)('_result.warnings'),
    /**
     * This hold all the logic for the above CPs. We do this so we can easily switch this object out with a different validations object
     * @property _result
     * @private
     * @type {Result}
     */
    _result: (0, _object.computed)('model', 'attribute', '_promise', '_validator', '_resultOverride', function () {
      return (0, _object.get)(this, '_resultOverride') || _internalResultObject.default.create((0, _object.getProperties)(this, ['model', 'attribute', '_promise', '_validator']));
    }),
    init() {
      this._super(...arguments);
      if ((0, _object.get)(this, 'isAsync') && !(0, _object.get)(this, '_isReadOnly')) {
        this._handlePromise();
      }
    },
    /**
     * Update the current validation result object with the given value
     * - If value is undefined or null, set isValid to false
     * - If value is a validations object from a different model/object, set the _result object to the one given (belongs-to)
     * - If value is a collection of result objects, create a Validation Result Collection and set that to the _result object (has-many)
     * - If value is a string, set the message to the given string and set isValid to false
     * - If value is a boolean, set isValid to result
     * - If value is a pojo, update _result object with the information given
     *
     * @method update
     * @private
     * @param value
     */
    update(value) {
      let result = (0, _object.get)(this, '_result');
      let attribute = (0, _object.get)(this, 'attribute');
      let isWarning = (0, _object.get)(this, 'isWarning');
      let Collection = isWarning ? _warningResultCollection.default : _resultCollection.default;
      if ((0, _utils.isNone)(value)) {
        return this.update(false);
      } else if ((0, _object.get)(value, 'isValidations')) {
        this._overrideResult(Collection.create({
          attribute,
          content: [value]
        }));
      } else if ((0, _array.isArray)(value)) {
        this._overrideResult(Collection.create({
          attribute,
          content: value
        }));
      } else if (!(0, _object.get)(this, '_isReadOnly')) {
        this._overrideResult(undefined);
        if (typeof value === 'string') {
          (0, _object.setProperties)((0, _object.get)(this, '_result'), {
            [isWarning ? 'warningMessage' : 'message']: value,
            isValid: isWarning ? true : false
          });
        } else if (typeof value === 'boolean') {
          (0, _object.set)(result, 'isValid', value);
        } else if (typeof value === 'object') {
          (0, _object.setProperties)(result, value);
        }
      }
    },
    /**
     * Override the internal _result property.
     * @method _overrideResult
     * @param result
     * @private
     */
    _overrideResult(result) {
      (0, _object.set)(this, '_resultOverride', result);
    },
    /**
     * Promise handler
     * @method _handlePromise
     * @private
     */
    _handlePromise() {
      (0, _object.get)(this, '_promise').then(value => this.update(value), value => this.update(value)).catch(reason => {
        // TODO: send into error state
        throw reason;
      });
    }
  });
  var _default = _exports.default = Result;
});