define("ember-local-storage/helpers/import-export", ["exports", "rsvp", "@ember/object", "@ember/runloop", "ember-inflector", "@ember/array"], function (_exports, _rsvp, _object, _runloop, _emberInflector, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.exportData = exportData;
  _exports.importData = importData;
  function importData(store, content, options) {
    // merge defaults
    options = Object.assign({
      json: true,
      truncate: true
    }, options || {});
    let truncateTypes = (0, _array.A)(),
      reloadTypes = (0, _array.A)();
    content = options.json ? JSON.parse(content) : content;
    if (options.truncate) {
      content.data.forEach(record => {
        truncateTypes.addObject(record.type);
      });
      truncateTypes.forEach(type => {
        const singularType = (0, _emberInflector.singularize)(type);
        const adapter = store.adapterFor(singularType);
        adapter._getIndex(type).forEach(storageKey => {
          delete (0, _object.get)(adapter, '_storage')[storageKey];
        });
        adapter._getIndex(type).reset();

        // unload from store
        store.unloadAll(singularType);
      });
    }
    const promises = content.data.map(record => {
      const adapter = store.adapterFor((0, _emberInflector.singularize)(record.type));

      // collect types to reload
      reloadTypes.addObject((0, _emberInflector.singularize)(record.type));
      return adapter._handleStorageRequest(null, 'POST', {
        data: {
          data: record
        }
      });
    });
    return (0, _rsvp.all)(promises).then(function () {
      // reload from store
      reloadTypes.forEach(function (type) {
        store.findAll(type);
      });
    });
  }
  function exportData(store, types, options) {
    // merge defaults
    options = Object.assign({
      json: true,
      download: false,
      filename: 'ember-data.json'
    }, options || {});
    let json, data;

    // collect data
    data = types.reduce((records, type) => {
      const adapter = store.adapterFor((0, _emberInflector.singularize)(type));
      const url = adapter.buildURL(type);
      const exportData = adapter._handleGETRequest(url);
      records.data = records.data.concat(exportData);
      return records;
    }, {
      data: []
    });
    if (options.json || options.download) {
      json = JSON.stringify(data);
    }
    if (options.json) {
      data = json;
    }
    if (options.download) {
      window.saveAs(new Blob([json], {
        type: 'application/json;charset=utf-8'
      }), options.filename);
    }
    return new _rsvp.Promise(resolve => {
      (0, _runloop.run)(null, resolve, data);
    }, 'DS: LocalStorageAdapter#exportData');
  }
});