import { later } from '@ember/runloop';
import { expectTypeOf } from 'expect-type';

class Foo {
  test(_foo, _bar, _baz) {
    return 1;
  }

}

let foo = new Foo(); // With only function

expectTypeOf(later((_foo, _bar, _baz) => {
  return 1;
}, 1, true, undefined, 1)).toEqualTypeOf();
expectTypeOf(later((_foo, _bar, _baz) => {
  return 1;
}, 1, true, 'string', 1)).toEqualTypeOf();
later(_foo => {
  return 1; // @ts-expect-error invalid argument
}, 'string'); // With target and function

expectTypeOf(later(foo, function (_foo, _bar, _baz) {
  expectTypeOf(this).toEqualTypeOf();
  return 1;
}, 1, true, undefined, 1)).toEqualTypeOf();
expectTypeOf(later(foo, function (_foo, _bar, _baz) {
  return 1;
}, 1, true, 'string', 1)).toEqualTypeOf(); // @ts-expect-error invalid args

later(foo, function (_foo, _bar, _baz) {
  return 1;
}, 1, 'string', true, 1); // With function string reference

expectTypeOf(later(foo, 'test', 1, true, 'string', 1)).toEqualTypeOf();
expectTypeOf(later(foo, 'test', 1, true, undefined, 1)).toEqualTypeOf(); // @ts-expect-error Invalid args

later(foo, 'test', 'string');