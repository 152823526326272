import { scheduleOnce } from '@ember/runloop';
import { expectTypeOf } from 'expect-type';

class Foo {
  test(_foo, _bar, _baz) {
    return 1;
  }

}

let foo = new Foo(); // With only function

expectTypeOf(scheduleOnce('my-queue', (_foo, _bar, _baz) => {
  return 1;
}, 1, true, undefined)).toEqualTypeOf();
expectTypeOf(scheduleOnce('my-queue', (_foo, _bar, _baz) => {
  return 1;
}, 1, true, 'string')).toEqualTypeOf();
scheduleOnce('my-queue', _foo => {
  return 1;
}, // @ts-expect-error invalid argument
'string'); // With target and function

expectTypeOf(scheduleOnce('my-queue', foo, function (_foo, _bar, _baz) {
  expectTypeOf(this).toEqualTypeOf();
  return 1;
}, 1, true)).toEqualTypeOf();
expectTypeOf(scheduleOnce('my-queue', foo, function (_foo, _bar, _baz) {
  return 1;
}, 1, true, 'string')).toEqualTypeOf(); // @ts-expect-error invalid args

scheduleOnce('my-queue', foo, function (_foo, _bar, _baz) {
  return 1;
}, 1, 'string', true); // With function string reference

expectTypeOf(scheduleOnce('my-queue', foo, 'test', 1, true, 'string')).toEqualTypeOf();
expectTypeOf(scheduleOnce('my-queue', foo, 'test', 1, true)).toEqualTypeOf(); // @ts-expect-error Invalid args

scheduleOnce('my-queue', foo, 'test', 'string');