/**
@module ember
*/
export default function ActionManager() {}
/**
  Global action id hash.

  @private
  @property registeredActions
  @type Object
*/

ActionManager.registeredActions = {};