import { bind } from '@ember/runloop';
import { expectTypeOf } from 'expect-type';

class Foo {
  test(_foo, _bar, _baz) {
    return 1;
  }

}

let foo = new Foo(); // With only function

expectTypeOf(bind((_foo, _bar, _baz) => {
  return 1;
})).toEqualTypeOf();
expectTypeOf(bind((_foo, _bar, _baz) => {
  return 1;
}, 1)).toEqualTypeOf();
expectTypeOf(bind((_foo, _bar, _baz) => {
  return 1;
}, 1, true)).toEqualTypeOf();
expectTypeOf(bind((_foo, _bar, _baz) => {
  return 1;
}, 1, true, 'baz')).toEqualTypeOf();
expectTypeOf(bind((_foo, _bar, _baz) => {
  return 1;
}, 1, true, undefined)).toEqualTypeOf();
bind(_foo => {
  return 1; // @ts-expect-error invalid argument
}, 'string'); // With target and function

expectTypeOf(bind(foo, function (_foo, _bar, _baz) {
  expectTypeOf(this).toEqualTypeOf();
  return 1;
})).toEqualTypeOf();
expectTypeOf(bind(foo, function (_foo, _bar, _baz) {
  return 1;
}, 1)).toEqualTypeOf();
expectTypeOf(bind(foo, function (_foo, _bar, _baz) {
  return 1;
}, 1, true)).toEqualTypeOf();
expectTypeOf(bind(foo, function (_foo, _bar, _baz) {
  return 1;
}, 1, true, 'baz')).toEqualTypeOf();
expectTypeOf(bind(foo, function (_foo, _bar, _baz) {
  return 1;
}, 1, true, undefined)).toEqualTypeOf(); // @ts-expect-error Invalid args

bind(foo, function (_foo) {
  return 1;
}, 'string'); // With function string reference

expectTypeOf(bind(foo, 'test')).toEqualTypeOf();
expectTypeOf(bind(foo, 'test', 1)).toEqualTypeOf();
expectTypeOf(bind(foo, 'test', 1, true)).toEqualTypeOf();
expectTypeOf(bind(foo, 'test', 1, true, 'baz')).toEqualTypeOf();
expectTypeOf(bind(foo, 'test', 1, true, undefined)).toEqualTypeOf(); // @ts-expect-error Invalid args

bind(foo, 'test', 'string');