window.is_turkmen = getCookie('IS_TURKMEN') === 'true';
window.GLOBAL_API = {}

window.loadPartnerConfigPromise = null;

checkPartner()

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

function domain_from_url(origin) {
  var result
  var match
  if (match = origin.match(/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n\?\=]+)/im)) {
    result = match[1]
    if (match = result.match(/^[^\.]+\.(.+\..+)$/)) {
      result = match[1]
    }
  }
  return result
}

function checkPartner() {
  $.get("https://ipv4-internet.yandex.net/api/v0/ip", function (response) {
    window.ip = response;
  }, "jsonp");
  let origin = window.location.origin;
  let mainDomain = domain_from_url(origin);

  window.loadPartnerConfigPromise = jQuery.ajax({
    type: "GET",
    url: `/casinoapi/api/config`,
    success: (data) => {
      window.partnerConfigs = data;
      window.GLOBAL_API = data.global_api;
    },
    cache: true,
  });
}
