define("ember-power-select/components/power-select/before-options", ["exports", "@ember/component", "@glimmer/component", "@ember/runloop", "@ember/object", "@ember/template-factory"], function (_exports, _component, _component2, _runloop, _object, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @searchEnabled}}
    <div class="ember-power-select-search">
      <input type="search"
        autocomplete="off"
        autocorrect="off"
        autocapitalize="off"
        spellcheck={{false}}
        class="ember-power-select-search-input"
        value={{@select.searchText}}
        aria-activedescendant={{@ariaActiveDescendant}}
        aria-controls={{@listboxId}}
        aria-haspopup="listbox"
        placeholder={{@searchPlaceholder}}
        aria-label={{@ariaLabel}}
        aria-labelledby={{@ariaLabelledBy}}
        {{on "input" @onInput}}
        {{on "focus" @onFocus}}
        {{on "blur" @onBlur}}
        {{on "keydown" this.handleKeydown}}
        {{did-insert this.focusInput}}
        {{will-destroy this.clearSearch}}>
    </div>
  {{/if}}
  
  */
  {
    "id": "oeAo4sYm",
    "block": "[[[41,[30,1],[[[1,\"  \"],[10,0],[14,0,\"ember-power-select-search\"],[12],[1,\"\\n    \"],[11,\"input\"],[24,\"autocomplete\",\"off\"],[24,\"autocorrect\",\"off\"],[24,\"autocapitalize\",\"off\"],[16,\"spellcheck\",false],[24,0,\"ember-power-select-search-input\"],[16,2,[30,2,[\"searchText\"]]],[16,\"aria-activedescendant\",[30,3]],[16,\"aria-controls\",[30,4]],[24,\"aria-haspopup\",\"listbox\"],[16,\"placeholder\",[30,5]],[16,\"aria-label\",[30,6]],[16,\"aria-labelledby\",[30,7]],[24,4,\"search\"],[4,[38,1],[\"input\",[30,8]],null],[4,[38,1],[\"focus\",[30,9]],null],[4,[38,1],[\"blur\",[30,10]],null],[4,[38,1],[\"keydown\",[30,0,[\"handleKeydown\"]]],null],[4,[38,2],[[30,0,[\"focusInput\"]]],null],[4,[38,3],[[30,0,[\"clearSearch\"]]],null],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"@searchEnabled\",\"@select\",\"@ariaActiveDescendant\",\"@listboxId\",\"@searchPlaceholder\",\"@ariaLabel\",\"@ariaLabelledBy\",\"@onInput\",\"@onFocus\",\"@onBlur\"],false,[\"if\",\"on\",\"did-insert\",\"will-destroy\"]]",
    "moduleName": "ember-power-select/components/power-select/before-options.hbs",
    "isStrictMode": false
  });
  let BeforeOptions = _exports.default = (_class = class BeforeOptions extends _component2.default {
    clearSearch() {
      (0, _runloop.scheduleOnce)('actions', this.args.select.actions, 'search', '');
    }
    handleKeydown(e) {
      if (this.args.onKeydown(e) === false) {
        return false;
      }
      if (e.keyCode === 13) {
        this.args.select.actions.close(e);
      }
    }
    focusInput(el) {
      (0, _runloop.later)(() => {
        if (this.args.autofocus !== false) {
          el.focus();
        }
      }, 0);
    }
  }, _applyDecoratedDescriptor(_class.prototype, "clearSearch", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "clearSearch"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleKeydown", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleKeydown"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "focusInput", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "focusInput"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, BeforeOptions);
});