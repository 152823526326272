import { debounce } from '@ember/runloop';
import { expectTypeOf } from 'expect-type'; // From Docs

function whoRan() {// Do stuff
}

let myContext = {
  name: 'debounce',

  test(_foo, _baz) {}

};
debounce(myContext, whoRan, 150); // less than 150ms passes

debounce(myContext, whoRan, 150);
debounce(myContext, whoRan, 150, true); // console logs 'debounce ran.' one time immediately.
// 100ms passes

debounce(myContext, whoRan, 150, true); // 150ms passes and nothing else is logged to the console and
// the debouncee is no longer being watched

debounce(myContext, whoRan, 150, true); // console logs 'debounce ran.' one time immediately.
// 150ms passes and nothing else is logged to the console and
// the debouncee is no longer being watched
// Method only

expectTypeOf(debounce((_foo, _baz) => {}, 1, undefined, 1)).toEqualTypeOf(); // @ts-expect-error Requires wait

debounce((_foo, _baz) => {}, 1, true); // @ts-expect-error Requires all args

debounce((_foo, _baz) => {}, 1, 1); // Can set immediate

debounce((_foo, _baz) => {}, 1, true, 1, true); // With target

debounce(myContext, function (_foo, _baz) {
  expectTypeOf(this).toEqualTypeOf(myContext);
}, 1, true, 1, true); // With key

debounce(myContext, 'test', 1, true, 1, true); // @ts-expect-error invalid key

debounce(myContext, 'invalid');

class Foo {
  test(_foo, _bar, _baz) {
    return 1;
  }

}

let foo = new Foo(); // With only function

expectTypeOf(debounce((_foo, _bar, _baz) => {
  return 1;
}, 1, true, undefined, 1)).toEqualTypeOf();
expectTypeOf(debounce((_foo, _bar, _baz) => {
  return 1;
}, 1, true, 'string', 1)).toEqualTypeOf();
debounce(_foo => {
  return 1; // @ts-expect-error invalid argument
}, 'string'); // With target and function

expectTypeOf(debounce(foo, function (_foo, _bar, _baz) {
  expectTypeOf(this).toEqualTypeOf();
  return 1;
}, 1, true, undefined, 1)).toEqualTypeOf();
expectTypeOf(debounce(foo, function (_foo, _bar, _baz) {
  return 1;
}, 1, true, 'string', 1)).toEqualTypeOf(); // @ts-expect-error invalid args

debounce(foo, function (_foo, _bar, _baz) {
  return 1;
}, 1, 'string', true, 1); // With function string reference

expectTypeOf(debounce(foo, 'test', 1, true, 'string', 1)).toEqualTypeOf();
expectTypeOf(debounce(foo, 'test', 1, true, undefined, 1)).toEqualTypeOf(); // @ts-expect-error Invalid args

debounce(foo, 'test', 'string');