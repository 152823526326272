define("ember-local-storage/helpers/utils", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.save = save;
  _exports.saveIfChanged = saveIfChanged;
  function save() {
    this._super.apply(this, arguments);
    this._save();
  }
  function saveIfChanged(key) {
    this._super.apply(this, arguments);
    if (key !== '_isInitialContent') {
      this._save();
    }
  }
});