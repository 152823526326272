define("ember-validators/utils/is-promise", ["exports", "ember"], function (_exports, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isPromise;
  const {
    canInvoke
  } = _ember.default;
  function isPromise(p) {
    return !!(p && canInvoke(p, 'then'));
  }
});