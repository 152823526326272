import { once } from '@ember/runloop';
import { expectTypeOf } from 'expect-type';

class Foo {
  test(_foo, _bar, _baz) {
    return 1;
  }

}

let foo = new Foo(); // With only function

expectTypeOf(once((_foo, _bar, _baz) => {
  return 1;
}, 1, true, undefined)).toEqualTypeOf();
expectTypeOf(once((_foo, _bar, _baz) => {
  return 1;
}, 1, true, 'string')).toEqualTypeOf();
once(_foo => {
  return 1; // @ts-expect-error invalid argument
}, 'string'); // With target and function

expectTypeOf(once(foo, function (_foo, _bar, _baz) {
  expectTypeOf(this).toEqualTypeOf();
  return 1;
}, 1, true)).toEqualTypeOf();
expectTypeOf(once(foo, function (_foo, _bar, _baz) {
  return 1;
}, 1, true, 'string')).toEqualTypeOf(); // @ts-expect-error invalid args

once(foo, function (_foo, _bar, _baz) {
  return 1;
}, 1, 'string', true); // With function string reference

expectTypeOf(once(foo, 'test', 1, true, 'string')).toEqualTypeOf();
expectTypeOf(once(foo, 'test', 1, true)).toEqualTypeOf(); // @ts-expect-error Invalid args

once(foo, 'test', 'string');