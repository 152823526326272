import { get, setProperties, computed, Mixin } from '@ember/-internals/metal';
import EmberError from '@ember/error';
/**
  @module @ember/object
*/

function tap(proxy, promise) {
  setProperties(proxy, {
    isFulfilled: false,
    isRejected: false
  });
  return promise.then(value => {
    if (!proxy.isDestroyed && !proxy.isDestroying) {
      setProperties(proxy, {
        content: value,
        isFulfilled: true
      });
    }

    return value;
  }, reason => {
    if (!proxy.isDestroyed && !proxy.isDestroying) {
      setProperties(proxy, {
        reason,
        isRejected: true
      });
    }

    throw reason;
  }, 'Ember: PromiseProxy');
}

const PromiseProxyMixin = Mixin.create({
  /**
    If the proxied promise is rejected this will contain the reason
    provided.
       @property reason
    @default null
    @public
  */
  reason: null,

  /**
    Once the proxied promise has settled this will become `false`.
       @property isPending
    @default true
    @public
  */
  isPending: computed('isSettled', function () {
    return !get(this, 'isSettled');
  }).readOnly(),

  /**
    Once the proxied promise has settled this will become `true`.
       @property isSettled
    @default false
    @public
  */
  isSettled: computed('isRejected', 'isFulfilled', function () {
    return get(this, 'isRejected') || get(this, 'isFulfilled');
  }).readOnly(),

  /**
    Will become `true` if the proxied promise is rejected.
       @property isRejected
    @default false
    @public
  */
  isRejected: false,

  /**
    Will become `true` if the proxied promise is fulfilled.
       @property isFulfilled
    @default false
    @public
  */
  isFulfilled: false,

  /**
    The promise whose fulfillment value is being proxied by this object.
       This property must be specified upon creation, and should not be
    changed once created.
       Example:
       ```javascript
    import ObjectProxy from '@ember/object/proxy';
    import PromiseProxyMixin from '@ember/object/promise-proxy-mixin';
       ObjectProxy.extend(PromiseProxyMixin).create({
      promise: <thenable>
    });
    ```
       @property promise
    @public
  */
  promise: computed({
    get() {
      throw new EmberError("PromiseProxy's promise must be set");
    },

    set(_key, promise) {
      return tap(this, promise);
    }

  }),

  /**
    An alias to the proxied promise's `then`.
       See RSVP.Promise.then.
       @method then
    @param {Function} callback
    @return {RSVP.Promise}
    @public
  */
  then: promiseAlias('then'),

  /**
    An alias to the proxied promise's `catch`.
       See RSVP.Promise.catch.
       @method catch
    @param {Function} callback
    @return {RSVP.Promise}
    @since 1.3.0
    @public
  */
  catch: promiseAlias('catch'),

  /**
    An alias to the proxied promise's `finally`.
       See RSVP.Promise.finally.
       @method finally
    @param {Function} callback
    @return {RSVP.Promise}
    @since 1.3.0
    @public
  */
  finally: promiseAlias('finally')
});

function promiseAlias(name) {
  return function (...args) {
    let promise = get(this, 'promise'); // We need this cast because `Parameters` is deferred so that it is not
    // possible for TS to see it will always produce the right type. However,
    // since `AnyFn` has a rest type, it is allowed. See discussion on [this
    // issue](https://github.com/microsoft/TypeScript/issues/47615).

    return promise[name](...args);
  };
}

export default PromiseProxyMixin;