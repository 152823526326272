define("ember-validators/length", ["exports", "@ember/utils", "ember-validators/utils/validation-error"], function (_exports, _utils, _validationError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validateLength;
  /**
   *  @class Length
   *  @module Validators
   */

  /**
   * @method validate
   * @param {Any} value
   * @param {Object} options
   * @param {Boolean} options.allowNone If true, skips validation if the value is null or undefined. __Default: true__
   * @param {Boolean} options.allowBlank If true, skips validation if the value is empty
   * @param {Boolean} options.useBetweenMessage If true, uses the between error message when `max` and `min` are both set
   * @param {Number} options.is The exact length the value can be
   * @param {Number} options.min The minimum length the value can be
   * @param {Number} options.max The maximum length the value can be
   * @param {Object} model
   * @param {String} attribute
   */
  function validateLength(value, options) {
    let {
      allowNone = true,
      allowBlank,
      useBetweenMessage,
      is,
      min,
      max
    } = options;
    if ((0, _utils.isNone)(value)) {
      return allowNone ? true : (0, _validationError.default)('invalid', value, options);
    }
    if (allowBlank && (0, _utils.isEmpty)(value)) {
      return true;
    }
    let length = value.length;
    if (!(0, _utils.isNone)(is) && is !== length) {
      return (0, _validationError.default)('wrongLength', value, options);
    }
    if (useBetweenMessage && !(0, _utils.isNone)(min) && !(0, _utils.isNone)(max) && (length < min || length > max)) {
      return (0, _validationError.default)('between', value, options);
    }
    if (!(0, _utils.isNone)(min) && min > length) {
      return (0, _validationError.default)('tooShort', value, options);
    }
    if (!(0, _utils.isNone)(max) && max < length) {
      return (0, _validationError.default)('tooLong', value, options);
    }
    return true;
  }
});